<template>
  <div class="detail">
    <!-- 计划详情 -->
    <nav-bar :nav-info='navInfo'></nav-bar>
    <div class="Box">
      <div class="detailsBox" v-if="dataList.detail">
      <!-- <div class="detailsBox" > -->
        <p>卸货点数：<span>{{dataList.detail.pointNum}}</span></p>
        <p>附件：<span>{{dataList.detail.enclosure || '无'}}</span></p>
        <p>随货明细：<span>{{dataList.detail.detailed2 || '无'}}</span></p>
        <p>备注1：<span>{{dataList.detail.text1 || '无'}}</span></p>
        <p>备注2：<span>{{dataList.detail.text2 || '无'}}</span></p>
      </div>
      <!-- tab切换 -->
      <div class="tabBar">
        <span :class="[currentTabIndex==0?'active':'']" @click="changeTab(0)">车辆信息</span>
        <span :class="[currentTabIndex==1?'active':'']" @click="changeTab(1)">订单信息</span>
      </div>
      <!-- currentTabIndex==0 车辆-->
      <div class="driverDetails" v-if="currentTabIndex==0">
        <div v-if="noDriverInfo">
          <div class="driverDetails_item" v-for="(item,index) in dataList.driverInfo" :key="index">
            <div class="driver_cz">
              <div class="driver_edit" @click="editDriver(item)">修改</div>
              <div class="driver_del"  @click="delDriver(item)">删除</div>
            </div>
            <div class="orderNo">
              <div class="orderNo_no">{{item.driverName}}</div>
            </div>
            <p>
              <span>车牌号：{{item.carNo}}</span>
              <span>电话：{{item.phone}}</span>
            </p>
          </div>
        </div>
        <div v-else>
          <van-empty
            class="custom-image"
            image="https://img01.yzcdn.cn/vant/empty-image-default.png"
            description="没有车辆信息"
          />
        </div>
      </div>
      <!-- currentTabIndex==1 订单信息-->
      <div class="orderDetails" v-if="currentTabIndex==1 ">
        <div v-if="noOrderInfo">
          <van-collapse v-model="activeName" accordion>
            <div v-for="(item,index) in dataList.orderInfo" :key="index">
              <div class="orderDetails_item">
                <van-collapse-item  :name="index" >
                  <template #title>
                    <div class="orderNo">
                      <div class="orderNo_img"><img :src="require('@/assets/img/icon/cg_order2x.png')"><img></div>
                      <div class="orderNo_no">{{item.orderNo || '无'}}</div>
                    </div>
                    <div class="order_info">                
                      <div class="order_info_item">
                        <span>数量：{{item.quantity|toFixed(2)}}</span>
                        <span>方位：{{item.volume|toFixed(2) }}立方</span>
                      </div>
                      <div class="order_info_item">
                        <span class="order_info_address">地址：{{item.receiverLocationAddress}}</span>
                      </div>
                    </div>
                  </template>
                  <!-- 折叠内容 -->
                  <h3>订单详情</h3>
                  <p class="orderItem">订货日期：{{item.orderGoodsDate || '无'}}</p>
                  <p class="orderItem">销售订单：{{item.orderCode || '无'}}</p>
                  <p class="orderItem">采购订单：{{item.cgOrder || '无'}}</p>
                  <p class="orderItem">行：{{item.lineNo || '无'}}</p>
                  <p class="orderItem">短缺量：{{item.shortage || '无'}}</p>
                  <p class="orderItem">体积：{{item.volume || 0}}m³</p>
                  <p class="orderItem">物料号：{{item.itemCode || '无'}}</p>
                  <p class="orderItem">产品型号：{{item.itemModel || '无'}}</p>
                  <p class="orderItem">名称：</p>
                  <p class="orderItem">运输公司：{{item.transCompany || '无'}}</p>
                  <p class="orderItem">备注：{{item.barDescription || '无'}}</p>
                </van-collapse-item>
              </div>
              <p class="jj"></p>
            </div>
          </van-collapse>
        </div>
        <div v-else>
          <van-empty
            class="custom-image"
            image="https://img01.yzcdn.cn/vant/empty-image-default.png"
            description="没有订单信息"
          />
        </div>  
      </div>
    </div>
    <!-- 新增司机 -->
    <div class="goPageBtn">
      <div class="add_driver" @click="addDriver">添加司机</div>
    </div>
  </div>
</template>
<script>
import { Toast } from 'vant';
import { Dialog } from 'vant';
import NavBar from '../../components/NavBar/NavBar.vue'
export default {
  components: {
    NavBar
  },
  data() {
    return {
      navInfo: {
        title:'计划详情',
        left:true,
        path:'/planWaybill'
      },
      currentTabIndex:0,
      activeName:'',
      dataList:{},
      noDriverInfo:true,
      noOrderInfo:true,
    }
  },
  created() {
    this.getList()
    if (this.$route.query.transportNo) {
      window.sessionStorage.setItem('transportNo',this.$route.query.transportNo)
      window.sessionStorage.setItem('transportNoId',this.$route.query.transportNoId)
    }
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    changeTab(value) {
     this.currentTabIndex = value
    },
    addDriver() {//添加司机
      console.log('新增司机');
      this.$router.push({
        path:'/register',
        query: {
          transportId:window.sessionStorage.getItem('transportNoId'),
          type:3,//计划新增
        }
      })
    },
    editDriver(item) {//修改司机
      console.log(item);
      this.$router.push({
        path:'/register',
        query: {
          transportNo:window.sessionStorage.getItem('transportNo'),
          driverInfo:item,
          type:3
        }
      })
    },
    async delDriver(item) {//删除司机
      let params = {
        planId:window.sessionStorage.getItem('transportNoId'),
        driverId:item.driverId
      }
      	Dialog.confirm({
				title: '删除',
				message: '是否确认删除运单下的该司机',
			}).then( async() => {
					let res = await this.$Api.delDriverBindingPlan(params)
					if (res.data=='删除成功') {
						Toast("删除成功")
            setTimeout(() => {
              this.getList()
            }, 1000);
					} else {
						Toast("删除失败")
					}
				})
				.catch(() => {
					Toast("已取消")
				});
    },
   //获取计划订单详情
   async getList() {
     console.log(this.$route.query);
     let  params = {
       transportNo:this.$route.query.transportNo || window.sessionStorage.getItem('transportNo'),
       id:this.$route.query.transportNoId || window.sessionStorage.getItem('transportNoId')
     }
     let res =  await this.$Api.planOrderDetail(params)
     if (res.status!=200) return false
     if (res.data[0].driverInfo.length!=0) {
       this.noDriverInfo =true
     }else {
        this.noDriverInfo =false
     }
     if (res.data[0].orderInfo.length!=0) {
       this.noOrderInfo =true
     }else {
        this.noOrderInfo =false
     }
     this.dataList = res.data[0]
   },
    //计算客户的计划容积
    sumVol(value) {
      let sum = 0
      value.item.map(element => {
        sum += Number(element.ldSize)
      })
      return sum
    },
  },
   beforeRouteLeave(to,from,next) {
    if (to.name=='PlanWaybill') {
        to.meta.KeepAlive = true
        next();
    } else {
        next();
    }
  }
}
</script>
<style lang="scss"  scoped>
.detail {
  height: 100vh;
  background: #f5f5f5;
  overflow:hidden;
}
.Box {
  padding-top: 0.27rem;
}
.detailsBox {
  font-size: 0.37rem;
  margin: 0.27rem 0.43rem;
  border-radius: 0.27rem;
  background: #ffffff;
  p {
    padding-left: 0.43rem;
    height: 0.53rem;
    overflow: hidden;
    span {
      color: #999999;
    }
  }
  p:nth-child(1) {
    padding-top: 0.27rem;
  }
   p:nth-child(5) {
    padding-bottom: 0.27rem;
  }
}
.tabBar {
  span{
    display: inline-block;
    margin:0 0.43rem;
    font-size: 0.42rem;
    color: #999999;
  }
  .active {
    color: #000000;
    font-size: 0.47rem;
    border-bottom: 0.1rem solid #FB6600;
  }
}

.driverDetails {
  margin: 0.27rem 0;
  // height: 11.5rem;
  height: 60vh;
  overflow-y: scroll;
  .driverDetails_item {
    position: relative;
    margin: 0.27rem 0.43rem;
    background: #ffffff;
    border-radius: 0.17rem;
    .driver_cz {
      font-size: 0.32rem;
      position: absolute;
      top: 0;
      right:0;
      display: flex;
      flex-direction: column;
      div {
        margin-top: 0.3rem;
        flex: 1;
        padding: 0.05rem 0.2rem;
        border-radius: 0.3rem;
        color: #fff;
        &:nth-child(1) {
          background-color: #0094ff;
        }
        &:nth-child(2) {
					background-color: #ff6600;
        }
      }
    }
    .orderNo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .orderNo_no {
        margin-left: 0.27rem;
        font-size: 0.37rem;
        flex: 1;
        height: 0.64rem;
        line-height: 0.64rem;
        padding-left: 0.64rem;
        background: url("../../assets/img/icon/waybill_user2x.png");
        background-size: 0.64rem;
        background-repeat: no-repeat;
      }
      .orderNo_img {
        margin-left: 0.43rem;
        width: 0.64rem;
        // height: 0.64rem;
        margin-top: -0.64rem;
        img {
          height: 100%;
        }
      }
    }
    p {
      padding: 0.27rem 0.43rem;
      font-size: 0.37rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        flex: 1;
        text-align: left;
      }
    }
  }
}

.orderDetails {
  // height: 11.5rem;
  height: 60vh;
  overflow-y: scroll;
  margin: 0.27rem 0.43rem;
  border-radius: 0.27rem!important;
  .orderDetails_item {
    margin-top: 0.27rem;
     border-radius: 0.27rem!important;
  }
  /deep/.van-collapse-item__title {
     border-radius: 0.27rem
  }
  /deep/.van-collapse-item__wrapper {
     border-radius: 0 0 0.27rem 0.27rem;
  }
  
  /deep/.van-cell__right-icon {
    display: none;
  }
  /deep/.van-cell::after {
    border:0
  }
  /deep/.van-cell {
    padding: 0;
  }
  /deep/.van-collapse-item__content {
    padding: 0;

  }
}

.orderNo {
  padding-top: 0.27rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 0.64rem;
  .orderNo_no {
    font-size: 0.37rem;
    flex: 1;
    height: 100%;
  }
  .orderNo_img {
    margin-left: 0.29rem;
    width: 0.64rem;
    height: 0.64rem;
    img {
      height: 100%;
    }
  }
}
.order_info {
  margin-left: 0.46rem;
  margin-bottom: 0.1rem;
  .order_info_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 0.27rem;
    .order_info_address {
      margin-bottom: 0.2rem;
    }
      span:nth-child(2) {
        width: 3.5rem!important;
      }
    
  }
}
h3 {
  font-size: 0.37rem;
  color: #FB6600;
  margin-left: 0.46rem;
}
.orderItem {
  margin-left: 0.46rem;
  color: #000000;
  padding: 0.05rem 0;
}
.jj {
  height: 0.2rem;
  background: #f5f5f5;
}

.add_driver {
  position: fixed;
  bottom: 20px;
  left: 0;
  margin-left: 10vw;
  text-align: center;
  line-height: 38px;
  border-radius: 19px;
  font-size: 0.42rem;
  color: #fff;
  background-color: #ff6600;
  height: 38px;
  width: 80vw;
}
</style>